import { useEffect, useMemo, useState } from 'react'

const useProductGrid = products => {
  const [selectedFilter, setSelectedFilter] = useState('')

  // Filtered products by selected tag
  const filteredProduct = useMemo(() => {
    if (!selectedFilter) {
      return products
    }

    return products
      .filter(product =>
        product.tags.includes(selectedFilter) ? product : null
      )
      .filter(item => item !== null)
  }, [products, selectedFilter])

  // Get the tags from products (to create the filtering)
  const filteredTags = useMemo(() => {
    return products
      .map(product => product.tags)
      .flat(1)
      .filter((value, index, self) => self.indexOf(value) === index)
  }, [products])

  // select the first tag by default on component mount
  useEffect(() => {
    setSelectedFilter(filteredTags[0])
  }, [])

  return {
    selectedFilter,
    setSelectedFilter,
    filteredProduct,
    filteredTags,
  }
}

export default useProductGrid
