import React from 'react'
import { RichText } from 'prismic-reactjs'
import { GatsbyImage } from 'gatsby-plugin-image'

import Container from '~/components/Container'
import ArrowLink from '~/components/ArrowLink'

import * as classes from './IntroSection.module.scss'

const IntroSection = ({ title, textRaw, link, gatsbyImageData }) => {
  return (
    <section className={classes.introSection}>
      <Container>
        <div className={classes.introSectionContainer}>
          <GatsbyImage {...gatsbyImageData} />
          <div className={classes.introSectionInner}>
            {title && <h1 className={classes.titleUnderline}>{title}</h1>}
            <div className={classes.contentText}>
              <RichText render={textRaw} />
            </div>
            {link.to && (
              <ArrowLink
                to={link.to}
                text={link.text}
                anchor={link.anchor}
                color={'yellow'}
              />
            )}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default IntroSection
