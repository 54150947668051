import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { formatPrice } from '~/utils/format-price'

import * as classes from './ProductCard.module.scss'

const ProductCard = ({ product }) => {
  const lowerPrice =
    product.data?.shopify?.variants &&
    product.data.shopify.variants.sort(
      (a, b) => parseFloat(a.price) - parseFloat(b.price)
    )

  const price =
    lowerPrice &&
    lowerPrice[0].price !== '0.00' &&
    formatPrice('NZD', lowerPrice[0].price)

  return (
    <div className={classes.productItem}>
      <div className={classes.imageContainer}>
        <Link to={`/product/${product.uid}`}>
          {product.data?.listing_image && (
            <GatsbyImage
              image={product.data.listing_image.gatsbyImageData}
              alt={product.data.listing_image.alt || product.data.title.text}
            />
          )}
        </Link>
      </div>
      <Link to={`/product/${product.uid}`} className={classes.productTitle}>
        <h3>{product.data.title.text}</h3>
        {price && <span>{price}</span>}
      </Link>
    </div>
  )
}

export default ProductCard
