import React from 'react'
import { array } from 'prop-types'
import ProductCard from '~/components/ProductCard'
import useProductGrid from './hooks/useProductGrid'

import * as classes from './ProductGrid.module.scss'

const ProductGrid = ({ products }) => {
  const { selectedFilter, setSelectedFilter, filteredProduct, filteredTags } =
    useProductGrid(products)

  if (!products) {
    return <div>No products available</div>
  }

  return (
    <div>
      <div className={classes.filters}>
        {filteredTags.map(tag => (
          <button
            key={tag}
            className={tag === selectedFilter ? classes.active : undefined}
            onClick={() => {
              setSelectedFilter(tag)
            }}
          >
            {tag}
          </button>
        ))}
      </div>
      <div className={classes.productGrid}>
        {filteredProduct.map((product, index) => (
          <ProductCard key={index} product={product} />
        ))}
      </div>
    </div>
  )
}

ProductGrid.prototype = {
  products: array,
}

export default ProductGrid
