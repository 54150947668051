/**
 * Formats a currency according to the user's locale
 * @param {string} currency The ISO currency code (i.e NZD)
 * @param {number} value The amount to format (i.e) 50
 * @returns {string} Return string with currency in front and 2 decimal (i.e. $50.00)
 */
export const formatPrice = (currency, value) =>
  Intl.NumberFormat('en-NZ', {
    currency,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(value)
