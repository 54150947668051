import React from 'react'
import { graphql } from 'gatsby'

import Layout from '~/components/Layout'
import Seo from '~/components/Seo'
import Container from '~/components/Container'
import ProductGrid from '~/components/ProductGrid'
import IntroSection from '~/components/IntroSection'

import * as classes from './ShopPage.module.scss'

const ShopPage = ({ data }) => {
  const shopPage = data.prismicShopPage.data
  const products = data.allPrismicProduct.nodes

  const { seo_title: seoTitle, seo_description: seoDescription } = shopPage

  const introSectionProps = {
    title: shopPage.intro_title.text,
    textRaw: shopPage.intro_text.raw,
    link: {
      to: '',
      text: 'Link',
    },
    gatsbyImageData: {
      image: shopPage.intro_illustrration.gatsbyImageData,
      alt: shopPage.intro_illustrration.alt,
    },
  }

  return (
    <Layout>
      <Seo title={seoTitle?.text} description={seoDescription?.text} />
      <div className={classes.introContainer}>
        <IntroSection {...introSectionProps} />
      </div>
      <div className={classes.productGridContainer}>
        <Container>
          <ProductGrid products={products} />
        </Container>
      </div>
    </Layout>
  )
}

export default ShopPage

export const query = graphql`
  query shopPage {
    prismicShopPage {
      data {
        seo_description {
          text
        }
        seo_title {
          text
        }
        intro_illustrration {
          gatsbyImageData(layout: CONSTRAINED, sizes: "250px")
          alt
        }
        intro_text {
          raw
        }
        intro_title {
          text
        }
        intro_link {
          slug
        }
      }
    }
    allPrismicProduct(limit: 100) {
      nodes {
        data {
          title {
            text
          }
          subtitle {
            text
          }
          listing_image {
            gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              sizes: "33vw"
            )
            alt
          }
          shopify {
            variants {
              price
            }
          }
        }
        uid
        tags
      }
    }
  }
`
